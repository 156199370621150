import Navbar from '../components/NavBar'
import Footer from '../components/Footer';
import BannerNosotros from '../components/nosotros/BannerNosotros';
import React, {useEffect} from 'react';
import IconsRedes from '../components/IconsRedes';

function Nosotros()
{
  return(
      <div className='App'>
          <Navbar />
          <div className='header-nos'></div>
          <BannerNosotros />
          <Footer />
          <IconsRedes />
      </div>
  );
}


export default Nosotros;