import './BannerServicios.css'

function BannerServicios() {
  return (
    <>
      <div className="image-inicio">
        <div className='typer-inicio'>
          Servicios Para Tu Estudio
        </div>
      </div>
    </>
  );
}

export default BannerServicios;
