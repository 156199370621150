import './BannerNosotros.css'
import {Image} from 'react-bootstrap';
import Equipo from '../../images/equipo_lr.png';
import { InstagramEmbed, TikTokEmbed } from 'react-social-media-embed';

const Instagram = () => {
  return (
    <>

    <div className='titleRedes'>
      Síguenos en nuestras redes
    </div>

    <div className='socialContainer'>

      <div className="socialItem1">
        <InstagramEmbed url="https://www.instagram.com/reel/C2TH3hTLHro/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="/> 
      </div>

      <div className="socialItem2" >
        <InstagramEmbed url="https://www.instagram.com/reel/C2I-gPHrHFI/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="/>
      </div>

    </div>

    <div className='socialContainer'>
      <div className="socialItem">
        <TikTokEmbed url="https://www.tiktok.com/@lr.solutions.sas/video/7339938570020392197?is_from_webapp=1&sender_device=pc&web_id=7322958228974568966" /> 
      </div>
    </div>
    </>
  );
};

function BannerNosotros() {
    return (
      <>
      <div className='contenedor-nosotros'>
       
        <div className='typer-nosotros'>

          <div
            className='titleNosotros'
          >
              Nuestra Historia
          </div>
          
            <b>LR SOLUTIONS</b> tiene sus raíces hace 17 años con el nacimiento de <b>Ladies Room Studio</b> en 2007. Guiados por la pasión y visión de nuestra CEO <b>Ivonne Puentes</b> e 
            Impulsados siempre por la amplia visión y búsqueda del perfeccionamiento continuo más individuos se sumaron a este viaje un equipo comprometido con el empoderamiento de las mujeres y 
            la excelencia en la industria. Esta expansión condujo en la apertura de una franquicia en la ciudad de <b>Bogotá</b>.
            El éxito de la franquicia abrió las puertas a todos nuestros estudios aliados que compartían la visión de innovación y compromiso en la industria.  
            <b>LR Solutions SAS</b> es una empresa dedicada a llevar a otros estudios al siguiente nivel, siguiendo los exitosos pasos de <b>Ladies Room Studio. </b>     
            LR se posicionó como la herramienta para compartir todo el conocimiento y la experiencia que transformaron a un modesto estudio en uno de los estudios 
            más prestigiosos de Colombia, fue así como múltiples estudios confiaron en nuestro acompañamiento y se unieron a esta familia como aliados viendo grandes resultados. 
            Este es apenas el inicio de nuestra historia; únete a nosotros mientras continuamos elevando la industria a nuevos horizontes.
        </div>

        <div className='image-nosotros'>
          <Image src={Equipo} 
          className='imagenEquipo'
          loading='lazy'
          />
        </div> 
      </div>
        
      
      <div style={{backgroundColor: 'white', paddingBottom: '10%'}}>
        <Instagram />
      </div>
      </>
    );
}

export default BannerNosotros;
