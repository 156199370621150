import Navbar from './components/NavBar'
import BannerInicio from './components/inicio/BannerInicio';
import Footer from './components/Footer';
import React, { useState, useEffect } from 'react';
import IconsRedes from './components/IconsRedes';
import './App.css';

function App() {
  const [backgroundColor, setBackgroundColor] = useState('linear-gradient(180deg, rgb(20, 20, 20), rgb(24, 17, 124), rgb(40, 31, 166))'); // Color inicial
  
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const maxScroll = 500; // Máximo desplazamiento donde se completa la transición
      const ratio = Math.min(scrollPosition / maxScroll, 1); // Ratio de desplazamiento (entre 0 y 1)
      // Definir los colores de inicio y fin para cada sección del degradado
      const startColors = [
        [20, 20, 20], // Negro grisáceo
        [24, 17, 124], // Azul claro
        [40, 31, 166] // Azul oscuro
      ];

      const endColors = [
        [40, 31, 166], // Azul claro
        [40, 31, 166], // Azul oscuro
        [20, 20, 20] // Azul oscuro
      ];
      // Calcular los colores interpolados para cada sección del degradado
      const interpolatedColors = startColors.map((startColor, index) => {
        const endColor = endColors[index];
        const interpolatedColor = startColor.map((channel, channelIndex) => {
          return Math.round(channel + (endColor[channelIndex] - channel) * ratio);
        });
        return interpolatedColor;
      });
      // Construir el degradado con los colores interpolados
      const gradientColors = interpolatedColors.map(color => `rgb(${color.join(', ')})`);
      const gradient = `linear-gradient(180deg, ${gradientColors[0]}, ${gradientColors[1]}, ${gradientColors[2]})`;
      // Actualizar el estado del color de fondo
      setBackgroundColor(gradient);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="App">
      <Navbar />
      <BannerInicio />
      <Footer />
      <IconsRedes />
    </div>
  );
}

export default App;
