import { Image } from 'react-bootstrap';
import React from 'react';
import logoImage from '../images/Ladies-room-blanco.png'; // Importar la imagen
import lr_logo from '../images/Ladies-room-blanco.png';
import './Footer.css'

function Footer() {
    return (
        <div className='footer'>
         <div className='footer2'>
           <div className='logo'>
               <Image src={logoImage} alt="Logo" fluid width="250" height="70"/>
            </div>  
             <div className='info'>
             <h2>Info de Contacto</h2>
             <div style={{margin: 'auto', width: '345px'}}>
                <div className='links'>
                    <a
                        href="https://wa.me/+573245980319"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="instagram-link"
                    >
                        <i className="fa-brands fa-whatsapp iconos"></i> +(57) 324 5980 319
                    </a>
                </div>
                
                <div className='links'>
                    <a
                        href="https://www.instagram.com/lrsolutions_sas?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
                        target="_blank"
                        rel="noopener noreferrer"
                        className="instagram-link"
                    >
                        <i className="fa-brands fa-instagram iconos"></i> lrsolutions_sas
                    </a>
                </div>

                <div className='links'>
                    <p><i className="fa-regular fa-envelope iconos"></i>  lrsolutionsempresarial@gmail.com</p>   
                </div>

                <div className='links'>
                    <p><i className="fa-solid fa-location-dot iconos"></i>  Calle 35 23 15 Villavicencio, Meta</p>
                </div>
            </div>
           </div>  
         </div>
         
         <div className='copyr'>
            <p><i className="fa-regular fa-copyright"></i><b> Copyright 2024 LRsolutions.</b></p>
            <p>Diseñado y Desarrollado</p>
            <Image src={lr_logo} alt="Logo" fluid width="100" height="40"/>
         </div>

        </div>
    );
  }

export default Footer;
