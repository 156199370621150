import './IconsRedes.css'

function IconsRedes() {

    const handleInstagram = () => {
        window.open("https://www.instagram.com/lrsolutions_sas?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==", "_blank");
    }

    const handleFacebook = () => {
        window.open("https://www.facebook.com/profile.php?id=61556077396235", "_blank");
    }

    const handleTiktok = () => {
        window.open("https://www.tiktok.com/@lr.solutions.sas?is_from_webapp=1&sender_device=pc", "_blank");
    }

    const handleWhatsapp = () => {
        window.open("https://wa.me/+573245980319", "_blank");
    }

    return (
        <div className="contain-icons">
            <i 
                className="fa-brands fa-facebook-f icon-facebook"
                onClick={handleFacebook}
            ></i>

            <i 
                className="fa-brands fa-instagram icon-instagram"
                onClick={handleInstagram}
            ></i>

            <i 
                className="fa-brands fa-tiktok icon-tiktok"
                onClick={handleTiktok}
            ></i>

            <i 
                className="fa-brands fa-whatsapp icon-whats"
                onClick={handleWhatsapp}
            ></i>
        </div>
    );
}

export default IconsRedes;