import Navbar from '../components/NavBar'
import Footer from '../components/Footer';
import React, {useEffect} from 'react';
import BannerContacto from '../components/contacto/BannerContacto';
import IconsRedes from '../components/IconsRedes';

function Contactanos()
{
  return(
      <div className='App'>
          <Navbar />
          <BannerContacto />
          <Footer />
          <IconsRedes />
      </div>
  );
}

export default Contactanos;