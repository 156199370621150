import React, { useEffect, useState } from 'react';
import { Image }  from 'react-bootstrap';
import './CardsServicios.css'

function BannerServicios() {
    const [servicios, setServicios] = useState([]);

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetch(process.env.PUBLIC_URL + '/data/servicios.json'); // Ruta del archivo JSON
          if (!response.ok) {
              throw new Error('Failed to fetch data');
          }
          const data = await response.json();
          setServicios(data);
        } catch (error) {
          console.error('Error fetching data: ', error);
        }
      };
      fetchData();
    }, []);
    return (
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', width: '100%', paddingRight: '10%', paddingLeft: '10%' }}>
        {servicios.map((item, index) => (
        <div key={index} className='cardServicios'>

          <div className='cardLogo'>
            <Image src={process.env.PUBLIC_URL + '/iconos/'+ item.icono+'.png'} className='icono' loading="lazy"/>
          </div>

          <div className='cardText'>
            <div style={{textAlign: 'left', fontSize: '30px', fontWeight: 'bold', marginBottom: '30px'}}>
              {item.titulo}
            </div>
            
            <div style={{textAlign: 'left', fontSize: '20px'}}>
              <label>{item.contenido}</label>
            </div>
          </div>

        </div>
        ))}
      </div>
    );
}

export default BannerServicios;