import Container from 'react-bootstrap/Container';
import React, { useState, useEffect } from 'react';
import { Nav, Navbar, Image } from 'react-bootstrap';
import logo from '../images/Ladies-room-blanco.png';
import './Navbar.css';
import { NavLink } from 'react-router-dom';

function Navegador() {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [navBackground, setNavBackground] = useState('linear-gradient(180deg, rgb(11, 33, 74), rgba(9, 0, 134, 0.24), rgba(230, 230, 230, 0))');
  const [styleBlur, setStyleBlur] = useState({ backdropFilter: 'blur(0px)' });
  const [menuActivo, setMenuActivo] = useState(false);
  const [sizeNav, setSizeNav] = useState('150px');

  const handleMenuToggle = () => {
    setMenuActivo(!menuActivo); // Cambiar el estado actual
    if (!menuActivo) {
      console.log("El menú fue activado");
      setNavBackground('rgba(252, 253, 255, 0.103)');
      setStyleBlur({ backdropFilter: 'blur(10px)' });
    } else {
      console.log("El menú fue desactivado");
      setNavBackground('linear-gradient(180deg, rgb(11, 33, 74), rgba(9, 0, 134, 0.24), rgba(230, 230, 230, 0))');
      setStyleBlur({ backdropFilter: 'blur(0px)' });
    }
  };

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  const handleResize = () => {
    if (window.innerWidth <= 1199) {
      setSizeNav('')
    } else {
      setSizeNav('150px')
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    }
    }, []);

  useEffect(() => {
    if (window.innerWidth <= 1199) {    //scrollPosition > 50 || 
      setSizeNav('')
    } 
    else 
    {
      setSizeNav('150px')
    }
  }, [scrollPosition]);

  const handleClick = () => {
    // Aquí puedes agregar la lógica adicional que desees
    console.log("El botón hamburguesa fue clickeado");
  };
  return (
    <>
      <Navbar expand="xl"  style={{height: sizeNav, background: navBackground, ...styleBlur }} className='navbar-dark navfix'>
      <Container >
        <Navbar.Brand className='d-xl-none'>
            <Image src={logo} alt="Logo" fluid width="100" height="70"/> 
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleMenuToggle}/>
        <Navbar.Collapse id="basic-navbar-nav" >
          <Nav className="mx-auto navbar-light">
            
            <Nav.Link as={NavLink} to="/" style={{marginTop: '15px'}}><label className='font-nav'>INICIO</label></Nav.Link>

            <Nav.Link as={NavLink} to="/Nosotros" style={{marginTop: '15px'}}><label className='font-nav'>NOSOTROS</label></Nav.Link>

            <Image src={logo} alt="Logo" fluid width="150" height="70" className='d-none d-xl-block' style={{marginLeft: '15px', marginRight: '15px'}}/>

            <Nav.Link as={NavLink} to="/Contactanos" style={{marginTop: '15px'}}><label className='font-nav'>CONTACTO</label></Nav.Link>

            <Nav.Link as={NavLink} to="/Servicios" style={{marginTop: '15px'}}><label className='font-nav'>SERVICIOS</label></Nav.Link>

          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </>
  );
}

export default Navegador;