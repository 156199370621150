import './BannerContacto.css';
import { useState } from 'react';
import {Form, Button, Spinner, Alert, Image} from 'react-bootstrap';
import Monedas1 from '../../images/anim-web/1.png';
import Monedas2 from '../../images/anim-web/3.png';
import Icon from '../../images/anim-web/2.png';

function BannerContacto() {
  const [email, setEmail] = useState('');
  const [celular, setCelular] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showDanger, setShowDanger] = useState(false);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleCelularChange = (event) => {
    const inputValue = event.target.value;
    // Verifica si el valor ingresado es numérico
    if (/^\d*$/.test(inputValue)) {
      // Si es numérico, actualiza el estado
      setCelular(inputValue);
    }
  };

  const handleSubmit = async () => {
    setShowDanger(false);

    if (!email || !celular) {
      setShowDanger(true);
      return;
    }

    if (celular.length > 10) {
      setShowDanger(true);
      return; 
    }

    if (!/\S+@\S+\.\S+/.test(email)) {
      setShowDanger(true);
      return; // Detener la función si el email no tiene el formato correcto
    }

    setIsLoading(true); // Mostrar el spinner al inicio de la solicitud
    try {
      const response = await fetch(`http://api.lrsolutionssas.com/api/guardar-contacto/${email}/${celular}`);
      if (response.ok) {
        const data = await response.json();
        setShowAlert(true);
        setCelular("");
        setEmail("");
      } else {
        
      }
    } catch (error) {
      
    } finally {
      setIsLoading(false); // Ocultar el spinner al finalizar la solicitud
    }
  };

  return (
    <>
      <div className="banner-contact">
        
        <div className='img-contact'>

           <Image 
            src={Icon} 
            className='icono-pesos'
           />

           <Image 
            src={Monedas1} 
            className='monedasFrente'
           />

           <Image 
            src={Monedas2} 
            className='monedasAtras'
           />

        </div>

        <div className="modal-contact">
            <label style={{marginBottom: '35px', fontWeight: 'bold'}}>
              Dejanos tu contacto y nos comunicaremos contigo
            </label>

          <div style={{marginBottom: '15px'}}>
            <label style={{fontSize: '20px', fontWeight: 'bold'}}>
              Correo Electronico
            </label>
            <Form.Control
              placeholder="Ejemplo@gmail.com"
              aria-label="correo"
              aria-describedby="basic-addon1"
              className="form-contact"
              value={email}
              onChange={handleEmailChange}
            />
          </div>
           
          <div style={{marginBottom: '45px'}}>
            <label style={{fontSize: '20px', fontWeight: 'bold'}}>
              Celular
            </label>
            <Form.Control
              placeholder="Ejem 3205606511"
              aria-label="celular"
              aria-describedby="basic-addon1"
              className="form-contact"
              type='numeric'
              value={celular}
              onChange={handleCelularChange}
            />
          </div>

          <div>
            {isLoading ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              <>
                {/* Mostrar la alerta si showAlert es true */}
                {showAlert && (
                  <Alert 
                  style={{
                    width: '80%',
                     margin: 'auto',
                      marginBottom: '15px',
                       fontWeight:'bold',
                        color:'white',
                         backgroundColor: '#1640CD',
                         borderColor: '#1640CD'
                  }}>
                    Se registró correctamente
                  </Alert>
                )}

                {showDanger && (
                  <Alert variant="danger" 
                  style={{
                    width: '80%', 
                    margin: 'auto', 
                    marginBottom: '15px',
                    fontWeight:'bold',
                    color:'white',
                    backgroundColor: '#CD1616',
                    borderColor: '#CD1616'
                  }}>
                    Datos No validos
                  </Alert>
                )}

                  

                <Button className="btnEnviar" onClick={handleSubmit}>
                  <b>Enviar</b>
                </Button>
              </>
            )}
          </div>

        </div>

      </div>
      <div style={{height: '150px'}}></div>
    </>
  );
}

export default BannerContacto;
