import Navbar from '../components/NavBar'
import Footer from '../components/Footer';
import BannerServicios from '../components/servicios/BannerServicios'
import React, {useEffect} from 'react';
import IconsRedes from '../components/IconsRedes';
import CardServicios from '../components/servicios/CardsServicios';

function Servicios()
{
    return(
        <div className='App'>
            <Navbar />
            <BannerServicios />
            <CardServicios />
            <Footer />
            <IconsRedes />
        </div>
    );
}


export default Servicios;