import React, { useState, useEffect, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Servicios from './pages/Servicios' 
import Nosotros from './pages/Nosotros'
import Contactanos from './pages/Contactanos'
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import FondoReal from './images/fondo-real.svg';
import logo from './images/Ladies-room-blanco.png';
import equipolr from './images/equipo_lr.png'
import monedasFrente from '../src/images/anim-web/1.png';
import monedasAtras from '../src/images/anim-web/3.png';
import pesos from '../src/images/anim-web/2.png';
// Componente Loader para mostrar mientras se carga la página

const Loader = () => {
  return (
    <>
      <div className='fondoLoad'>
        <div className="boxes">
          <div className="box">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
          </div>
          <div className="box">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
          </div>
          <div className="box">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
          </div>
          <div className="box">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
          </div>
        </div>
      </div>
    </>
  );
}

function ScrollToTopOnNavigation() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const Root = () => {
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    const imgmonedasFrente = new Image();
    imgmonedasFrente.onload = () => {
    };
    imgmonedasFrente.src = monedasFrente; // Establece la ruta del SVG como la fuente de la imagen
  }, []);

  useEffect(() => {
    const imgmonedasAtras = new Image();
    imgmonedasAtras.onload = () => {
    };
    imgmonedasAtras.src = monedasAtras; // Establece la ruta del SVG como la fuente de la imagen
  }, []);

  useEffect(() => {
    const imgpesos = new Image();
    imgpesos.onload = () => {
    };
    imgpesos.src = pesos; // Establece la ruta del SVG como la fuente de la imagen
  }, []);

  useEffect(() => {
    const imgequipolr = new Image();
    imgequipolr.onload = () => {
    };
    imgequipolr.src = equipolr; // Establece la ruta del SVG como la fuente de la imagen
  }, []);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
    };
    img.src = FondoReal; // Establece la ruta del SVG como la fuente de la imagen
  }, []);

  useEffect(() => {
    const img2 = new Image();
    img2.onload = () => {
      setTimeout(() => {
        setLoading(false);
      }, "4000");
    };
    img2.src = logo; // Establece la ruta del SVG como la fuente de la imagen
  }, []);

  return (
    <Router>
      {loading ? (
        <Loader /> // Muestra el componente Loader mientras loading sea true
      ) : (
        <div className='fondo'  /*style={{backgroundImage: `url(${FondoReal})`, backgroundColor: 'rgb(36, 94, 219)', backgroundSize: 'cover', backgroundAttachment: 'fixed'}}  */>
          <Suspense fallback={<Loader />}>
            <ScrollToTopOnNavigation />

            <Routes>
              <Route exact path="/" element={<App />} />
              <Route exact path="/Servicios" element={<Servicios />} />
              <Route exact path="/Nosotros" element={<Nosotros />} />
              <Route exact path="/Contactanos" element={<Contactanos />} />
            </Routes>
          </Suspense>
        </div>
      )}
    </Router>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Root />);
reportWebVitals();
