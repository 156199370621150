import React, { useState, useEffect } from 'react';
import './BannerInicio.css'
import Video from '../../video/video.webm';
import ImagenError from '../../images/fondo-grande.png';
import { Player } from 'video-react';
import { Image } from 'react-bootstrap'; 

const Studios = () => {
  const [studios, setStudios] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(process.env.PUBLIC_URL + '/data/studios.json'); // Ruta del archivo JSON
        if (!response.ok) {
            throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        setStudios(data);
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };
    fetchData();
  }, []);

  return (
    <div
      style={{margin: 'auto', width: '80%'}}
    >
      <div
        style={{
          fontWeight: 'bold',
          color: 'white',
          fontSize: '1.6rem',
          
        }}
      >
        Algunos de Nuestros Estudios Aliados
      </div>
      {studios.map((item, index) => (
        <Image src={process.env.PUBLIC_URL + '/logos/'+ item.id+'.png'} style={{width: '180px', marginLeft: '15px', marginRight: '15px', marginTop: '20px', marginBottom: '20px'}}/>
      ))}
    </div>
  );
}


function BannerInicio() {
  return (
      <>
        <div className='con-video'>
          <Player
            src={Video}
            autoPlay
            poster={ImagenError}
            muted
            loop />
        </div>

        <div className="texto-inicio1">
          <label>
            Rompe Barreras, No te limites, lleva tu estudio al siguiente Nivel...
          </label>
        </div>


        <Studios />
      </>
  );
}

export default BannerInicio;
